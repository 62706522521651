



















































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Collaborations",
})
export default class Collaborations extends Vue{
}
