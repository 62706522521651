





















import Vue from "vue";
import Intro from './Intro.vue';
import Links from './Links.vue';
import Header from './Header.vue';
import Essays from './Essays.vue';
import Skills from './Skills.vue';
import Contact from './Contact.vue';
import Startups from './Startups.vue';
import Podcasts from './Podcasts.vue';
import Projects from './Projects.vue';
import Interests from './Interests.vue';
import Component from "vue-class-component";
import ScrollToTop from './ScrollToTop.vue';
import Collaborations from './Collaborations.vue';
@Component({
  name: "HelloWorld",
  components: {
    Intro,
    Links,
    Header,
    Skills,
    Essays,
    Contact,
    Startups,
    Podcasts,
    Projects,
    Interests,
    ScrollToTop,
    Collaborations,
  }
})
export default class HelloWorld extends Vue{
}
