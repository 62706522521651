







































































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Links",
})
export default class Links extends Vue{
}
