



















import SWMixin from "@/mixins/sw.mixin";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
@Component({
  name: "SWUpdate",
  mixins: [SWMixin],
})
export default class SWUpdate extends mixins(SWMixin) {
    //
}
