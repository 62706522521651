var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center display-2 my-16 font-weight-bold"},[_vm._v("🌍 Open source")]),_c('EssaysSubscribe',{staticClass:"my-16"}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '💻 Distoro OS - Operating System for x86 architecture',
      href: 'https://github.com/moltouni/distoro-os/'
    },"tags":[
      'High School Project',
      'NASM',
      'C' ],"descriptions":[
    "        Uses FAT-12 filesystem, has working screen output, keyboard input,<br>\n      exceptions, interrupts and allows for the usage of 4GB of RAM."
    ],"links":[
    {
      name: 'GitHub',
      href: 'https://github.com/moltouni/distoro-os/',
    } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '🚀 FireStarter API - Progressive Startup API Boilerplate',
      href: 'https://github.com/AndromedaTechnology/firestarter-api'
    },"tags":[
      'TypeScript 3.8',
      'Koa.js 2.13.1',
      'MongoDB',
      'Mongoose',
      'Jest',
      'Docker' ],"descriptions":[
      "Easy to extend, Progressive and Scalable API boilerplate to power your startup." ],"points":[
      "<h4>Scalable architecture</h4>",
      "<h4>Easy to extend</h4>",
      "<h4>Bleeding-edge tools</h4>" ],"links":[
      {
        name: 'GitHub',
        href: 'https://github.com/AndromedaTechnology/firestarter-api',
      } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '🔭 Ethereum Observer',
      href: 'https://github.com/AndromedaTechnology/ethereum-observer-api'
    },"tags":[
      'Blockchain',
      'Ethereum',
      'Hardhat',
      'Solidity',
      'TypeScript 3.8',
      'Koa.js 2.13.1',
      'MongoDB',
      'Mongoose',
      'Jest',
      'Docker' ],"descriptions":[
      "Simple block and transaction tracker for Ethereum network. " ],"points":[
      "<h4>Tracking block and transaction creation</h4>",
      "<h4>Storing daily summary to Solidity smart contract</h4>" ],"links":[
      {
        name: 'GitHub - API',
        href: 'https://github.com/AndromedaTechnology/ethereum-observer-api',
      },
      {
        name: 'GitHub - Contract - Hardhat',
        href: 'https://github.com/AndromedaTechnology/ethereum-observer-contract-hardhat',
      } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }