











import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Header",
})
export default class Header extends Vue{
}
