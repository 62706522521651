var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center display-2 my-16 font-weight-bold"},[_vm._v("🚀 Startups")]),_c('EssaysSubscribe',{staticClass:"my-16"}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '🏄 Habitus - Gamified Emotions, Habit Tracker and Journal',
      href: 'https://habitus.today/'
    },"tags":[
      'TypeScript 3.8',
      'Koa.js 2.13.1',
      'Vue.js 2.6',
      'Vuetify 2.2',
      'Progressive Web App'
    ],"descriptions":[
      'State-of-the-art Tracker for emotions, habits and thoughts.' ],"points":[
      "<h4>Track emotions,</h4>",
      "<h4>Track thoughts (journal),</h4>",
      "<h4>Track habits (good or bad),</h4>",
      "<h4>Sound notifications,</h4>",
      "<h4>Performance graphs,</h4>",
      "<h4>Open source,</h4>",
      "<h4>Anonymous,</h4>",
      "<h4>Storing data only in your browser.</h4>" ],"links":[
      {
        name: 'Live App',
        href: 'https://habitus.today/',
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/HabitusToday',
      },
      {
        name: 'Medium',
        href: 'https://medium.com/@habitus.today',
      },
      {
        name: 'GitHub - Frontend',
        href: 'https://github.com/AndromedaTechnology/habitus',
      },
      {
        name: 'GitHub - API',
        href: 'https://github.com/AndromedaTechnology/habitus-api',
      } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '👨🏻‍🏫 Onetius - Slick and Powerful E-Learning Platform',
      href: 'https://onetius.com/'
    },"tags":[
      'Leadership',
      'Product Development',
      'API Development - Laravel',
      'Frontend Development - backbone.js' ],"descriptions":[
    "        Learn from others or start your own Courses with your friends or colleagues. <br>\n      Create Teams to host closed-down Courses for your Company, Family or Friends.",
    "<strong>4900+ Teachers and Students.</strong>" ],"points":[
    "<strong>Open - Anyone can start or follow a course.</strong>",
    "<strong>Unbounded lessons - text, audio, video, photos...</strong>",
    "<strong>Test your knowledge - MicroExams after each lesson.</strong>",
    "<strong>Compare your performance with others - PerformanceGraph.</strong>",
    "<strong>Community discussions.</strong>",
    "<strong>Collaborations - People Nearby.</strong>",
    "<strong>Teams - Public, Private Courses.</strong>" ],"links":[
    {
      name: 'Pitch [VIDEO]',
      href: 'https://www.youtube.com/watch?v=tEkkxMh8JxE',
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/user/Onetius',
    },
    {
      name: 'Facebook',
      href: 'https://facebook.com/Onetius',
    },
    {
      name: 'Press 1',
      href: 'https://www.mojarijeka.hr/onetius-znanje-dostupno-svima-kroz-novu-mreznu-platformu/',
    },
    {
      name: 'Press 2',
      href: 'https://www.netokracija.com/onetius-edukativna-platforma-mario-novak-113910',
    } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
      name: '💪 Iron Heart Festival - Sports Competition and Platform',
      href: 'https://ironheartfestival.com/'
    },"tags":[
      'Leadership',
      'Marketing',
      'Product Development',
      'API Development - Laravel'
    ],"descriptions":[
      "Sports Competition - Athletes compete for the title of <strong>'The most athletic individual on the Planet'</strong>.<br>\n      Online Platform - educational videos, training logs of athletes.",
      "<strong>7+ million views on YouTube.</strong><br>\n      <strong>20k+ subscribers on YouTube.</strong>" ],"points":[
      "<strong>Perfect mix of disciplines.</strong>",
      "<strong>Benchmark your speed, strength, agility.</strong>",
      "<strong>Compete with others once a year - at IHF Finals in Croatia.</strong>",
      "<strong>Track your progress online - Arena - Digital playground.</strong>",
      "<strong>Academy - Workshops and Lectures - Online and Offline in Croatia.</strong>" ],"links":[
    {
      name: 'Booklet [Slides]',
      href: 'https://docs.google.com/presentation/d/1mtKZiaNoi20_M2644vOoVItaEpQhodYhrDc5C_AVmAI/edit?usp=sharing',
    },
    {
      name: 'Aftermovie',
      href: 'https://www.youtube.com/watch?v=dELRiTYFX70',
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/user/heartrequired/videos?view=0&sort=p&flow=grid',
    },
    {
      name: 'Facebook',
      href: 'https://facebook.com/ironheartfestival',
    },
    {
      name: 'Press',
      href: 'https://www.jutarnji.hr/Promo/najbrze-rastuci-sportski-festival-u-europi-pocinje-za-11-dana-u-hrvatskoj/6484561/',
    } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }