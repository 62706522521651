























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import EssaysSubscribe from './EssaysSubscribe.vue';
@Component({
  name: "Essays",
  components: {
    EssaysSubscribe,
  },
})
export default class Essays extends Vue {}
