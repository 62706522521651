
























































































































































import Vue from "vue";
import Project from './Project.vue';
import Component from "vue-class-component";
import EssaysSubscribe from './EssaysSubscribe.vue';
@Component({
  name: "Startups",
  components: {
    Project,
    EssaysSubscribe,
  }
})
export default class Startups extends Vue{
}
