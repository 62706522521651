







































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Interests",
  components: {
  }
})
export default class Interests extends Vue{
}
