var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center display-2 my-16 font-weight-bold"},[_vm._v("🎧 Podcasts")]),_c('EssaysSubscribe',{staticClass:"my-16"}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
        name: '🇬🇧 Software Artisan Podcast: Question everything',
        href: 'https://www.mixcloud.com/moltouni/question-everything-software-artisan-4/'
      },"tags":[
        'Journaling',
        'Evaluating status and progress',
        'Adjusting your actions',
        'Choosing optimal solutions',
        'Improving yourself throughout time' ],"descriptions":[
      ],"points":[
        'To cut corners or to invest proper time to learn?',
        'Journaling',
        'Algorithms — chess for Technology enthusiasts',
        'Build as you learn',
        'Educational system',
        'Be able to play in an ever-changing World',
        'Scalable programming language',
        'Software Development' ],"links":[
        {
          name: '📣 MixCloud',
          href: 'https://www.mixcloud.com/moltouni/question-everything-software-artisan-4/',
        } ]}}),_c('Project',{staticClass:"my-16",attrs:{"headerLink":{
  name: '🇭🇷 Hack The Balkan: Testing for Beginners',
        href: 'https://soundcloud.com/moltouni/testiranje-za-pocetnike-hack-the-balkan-1-typescript-frontend-backend-programiranje'
      },"tags":[
        'Problem Decomposition',
        'Data Mocking',
        'Test Driven Development',
        'TypeScript',
        'Frontend',
        'Backend' ],"descriptions":[
      ],"points":[
          'Why do we test our apps?',
          'Which parts should we test?',
          'Problem Decomposition.',
          'Data mocking.' ],"links":[
        {
          name: 'SoundCloud',
          href: 'https://soundcloud.com/moltouni/testiranje-za-pocetnike-hack-the-balkan-1-typescript-frontend-backend-programiranje',
        } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }