


































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Intro",
})
export default class Intro extends Vue {}
