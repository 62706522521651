














import Vue from "vue";
import SkillLogo from './SkillLogo.vue';
import Component from "vue-class-component";
@Component({
  name: "SkillLogos",
  components: {
    SkillLogo
  }
})
export default class SkillLogos extends Vue{
}
