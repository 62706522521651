

















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({
  name: "SkillLogo",
})
export default class SkillLogo extends Vue{
  @Prop({ type: String, required: true, default: undefined }) src!: string;
  @Prop({ type: String, required: false, default: undefined }) name?: string;
  @Prop({ type: Number, required: false, default: 96 }) size?: number;
}
