








import Vue from "vue";
import Component from "vue-class-component";
@Component({
  name: "Contact",
})
export default class Contact extends Vue{
}
