

















































import Vue from "vue";
import SkillLogos from './SkillLogos.vue';
import Component from "vue-class-component";
@Component({
  name: "Skills",
  components: {
    SkillLogos,
  }
})
export default class Skills extends Vue{
}
